import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArrayCastPipe } from './form-array-cast.pipe';
import { FormGroupCastPipe } from './form-group-cast.pipe';
import { SortPipe } from './sort.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
    declarations: [FormArrayCastPipe, FormGroupCastPipe, SortPipe, SafeUrlPipe],
    imports: [CommonModule],
    exports: [FormArrayCastPipe, FormGroupCastPipe, SortPipe, SafeUrlPipe]
})
export class PipesModule {}
