<div class="envkv-values">
    <div class="envkv-text" [ngClass]="{closed: addClass}">
        <p>
            <strong>Space Star</strong> Energieverbrauch 4,9-5,5 l/100 km Benzin; CO<sub>2</sub>-Emission 112-125 g/km; CO<sub>2</sub>-Klasse C-D; 
            <strong>COLT 1.0</strong> Energieverbrauch 5,2-5,4 l/100 km Benzin; CO<sub>2</sub>-Emission 118-121 g/km; CO<sub>2</sub>-Klasse D; 
            <strong>COLT Hybrid</strong> Energieverbrauch 4,2-4,3 l/100 km Benzin; CO<sub>2</sub>-Emission 96-97 g/km; CO<sub>2</sub>-Klasse C; 
            <strong>ASX 1.0 Turbo</strong> Energieverbrauch 6,0 l/100 km Benzin; CO<sub>2</sub>-Emission 135 g/km; CO<sub>2</sub>-Klasse D; 
            <strong>ASX Mildhybrid 1.3 Turbo</strong> Energieverbrauch 6,0 l/100 km Benzin; CO<sub>2</sub>-Emission 135 g/km; CO<sub>2</sub>-Klasse D; 
            <strong>ASX Hybrid</strong> Energieverbrauch 4,7 l/100 km Benzin; CO<sub>2</sub>-Emission 107 g/km; CO<sub>2</sub>-Klasse C; kombinierte Werte; 
            <!-- <strong>ASX Plug-in Hybrid</strong> Energieverbrauch 13,3 kWh/100 km Strom & 1,3-1,4 l/100 km Benzin; CO<sub>2</sub>-Emission 30-31 g/km; CO<sub>2</sub>-Klasse B; gewichtet kombinierte Werte. Bei entladener Batterie: Energieverbrauch 5,3 l/100 km Benzin; CO<sub>2</sub>-Klasse D;  -->
            <strong>Eclipse Cross Plug-in Hybrid 4WD 2.4</strong> Energieverbrauch 17,5 kWh/100 km Strom & 2,0 l/100 km Benzin; CO<sub>2</sub>-Emission 46 g/km; CO<sub>2</sub>-Klasse B; gewichtet kombinierte Werte. Bei entladener Batterie: Energieverbrauch 7,3 l/100 km Benzin; CO<sub>2</sub>-Klasse F; kombinierte Werte.<sup class="footnote">**</sup>
        </p>
        <div class="envkv_arrow" [ngClass]="{show: _showArrow}" (click)="toggleClose()">
            <svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 22 22" width="24" height="24" class="lazyloaded">
                <path d="M11,22C4.93,22,0,17.07,0,11S4.93,0,11,0s11,4.93,11,11-4.93,11-11,11ZM11,1C5.49,1,1,5.49,1,11s4.49,10,10,10,10-4.49,10-10S16.51,1,11,1Zm0,13.31l-.32-.32h0s-4.68-4.68-4.68-4.68l.62-.62,4.38,4.38,4.38-4.38,.62,.62-5,5Z" style="fill:#000;"></path>
            </svg></div>
    </div>
</div>
