import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiService, NewsImage } from 'src/app/services/api.service';
import { News, NewsType } from 'src/app/interfaces/News';
import { TYPO3PageContentTypes } from 'src/app/interfaces/PageContentTypes';
import { M0010KeyVisualModuleType } from 'src/app/interfaces/molecules/m0010-keyvisual-module';
import { A00130UnderlineLink } from 'src/app/interfaces/atoms/a00130-underline-link';
import {
    M0020LinkLists,
    M0020LinkListsType
} from 'src/app/interfaces/molecules/m0020-link-list';
import { O020SubnavigationTextLink } from 'src/app/interfaces/organisms/o020-subnavigation-text-link';
import { M0070Tile } from '../../../interfaces/molecules/m0070-newsteaser-tile';
import { ContentElement } from '../../../interfaces/ContentElement';

@Component({
    selector: 'px-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
    TYPO3PageContentTypes = TYPO3PageContentTypes;

    @Input()
    newsid: string;
    NewsType = NewsType;
    news: News;
    contentElements: ContentElement[];
    newsImage: NewsImage;
    public m0010KeyVisualModuleType: M0010KeyVisualModuleType;
    public o020SubnavigationTextLink: O020SubnavigationTextLink;
    public o20DataAvailable: boolean;
    public o90DataAvailable: boolean;

    @ViewChild('stickyElement', { read: ElementRef }) set ft(
        stickyElement: ElementRef
    ) {
        if (!stickyElement) {
            return;
        }

        // this.observer = new IntersectionObserver(
        //     ([e]) => {
        //         if(e.intersectionRatio < 1) {
        //             console.log("[e]", e);
        //         }
        //         // e.target.classList.toggle('isSticky', e.intersectionRatio < 1),{threshold: [1]}
        //     }
        // )

        // this.observer.observe(stickyElement.nativeElement)
    }

    // private observer: IntersectionObserver;
    public summary: string[];
    related: M0070Tile[] = [];

    aboutMitsubishi: { headline: string; content: string };

    constructor(private api: ApiService) {
    }

    async ngOnInit() {
        this.summary = [];
        this.o20DataAvailable = false;
        this.o90DataAvailable = false;
        const data = await this.api.getNewsDetail(parseInt(this.newsid, 10));
        this.news = data.news;
        this.contentElements = this.news.pageContent;
        this.newsImage = this.api.getNewsPreview(this.news.uid, 'teaser', 1280);
        this.aboutMitsubishi = data.aboutMitsubishi;
        this.m0010KeyVisualModuleType = M0010KeyVisualModuleType.C;
        this.setO20Content(this.news);
        this.setSummary(this.news);
        this.related = this.news?.related.map((r) => this.buildTile(r));
    }

    stringify(data: any) {
        return JSON.stringify(data);
    }

    buildTile(news: News): M0070Tile {
        return {
            uid: news.uid,
            date: news.date,
            headline: news.headline,
            copy: news.summary,
            img: this.api.getNewsPreview(news.uid, 'teaser'),
            tags: [
                ...news.models.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.modelYears.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                }),
                ...news.tags.map((t) => {
                    return {
                        modifier: '',
                        text: t.title
                    };
                })
            ],
            detailUrl: news.detailUrl
        };
    }

    setSummary(data: News) {
        if (!data.summary) {
            return;
        }
        this.summary = data.summary.split(';');
    }

    setO20Content(data: News) {
        const tempM0020: M0020LinkLists = {
            links: [],
            modifier: M0020LinkListsType.B
        };

        if (data.assets?.length > 0) {
            const tempA00130: A00130UnderlineLink = {
                href: '#assets',
                text: 'Dazugehöriges Material',
                target: ''
            };

            tempM0020.links.push(tempA00130);
        }

        if (data.related?.length > 0) {
            const tempA00130: A00130UnderlineLink = {
                href: '#related',
                text: 'Ähnliche Artikel',
                target: ''
            };

            tempM0020.links.push(tempA00130);
        }

        this.o020SubnavigationTextLink = {
            m0020LinkListsContent: tempM0020
        };
        if (data.type === 'news') {
            this.o020SubnavigationTextLink.downloads = true;
        } else {
            this.o020SubnavigationTextLink.downloads = false;
        }

        this.o20DataAvailable = true;
    }
}
